import React from 'react'
import { Link } from 'react-router-dom';
import './styles.css';

const GlowyText = ({ text, className, to = '/' }) => (
  <Link className={`page-link ${className}`} to={to}>
    {text}
    <span aria-hidden="true" className="glowing-text">{text}</span>
  </Link>
)

export default GlowyText;
