import React from 'react';
import CountUp from 'react-countup';
import Views from '../Unsplash/assets/Views';
import Downloads from '../Unsplash/assets/Downloads';
import './styles.css';

const Statistics = ({ stats, hasLoaded }) => {
  return (
    <div className="stats-container">
      <div className="total-views">
        <Views className="icon" />
        <span className="text-and-count">
          <span className="text-card">Total Views</span>
          <span className="numbers-card">
            {hasLoaded && <CountUp formattingFn={n => n.toLocaleString()} end={stats.views} className="numbers-card" />}
            {!hasLoaded && <span style={{ visibility: 'hidden' }}>-</span>}
          </span>
        </span>
      </div>
      <div className="total-downloads">
        <Downloads className="icon" />
        <span className="text-and-count">
          <span className="text-card">Total Downloads</span>
          <span className="numbers-card">
            {hasLoaded && <CountUp formattingFn={n => n.toLocaleString()} end={stats.downloads} className="numbers-card" />}
            {!hasLoaded && <span style={{ visibility: 'hidden' }}>-</span>}
          </span>
        </span>
      </div>
    </div>
  )
}

export default Statistics;
// Component certifified by Léo
