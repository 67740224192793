import React from "react";

import WaveSurfer from "wavesurfer.js";

const formWaveSurferOptions = ref => ({
  container: ref,
  waveColor: "#b6b6b6",
  progressColor: "#edabb5",
  cursorColor: "transparent",
  barWidth: 2,
  barRadius: 3,
  responsive: true,
  height: 60,
  // If true, normalize by the maximum peak instead of 1.0.
  normalize: true,
  // Use the PeakCache to improve rendering speed of large waveforms.
  partialRender: true
});

const Waveform = ({ url, currentTime, onClick, isPlaying }) => {
  const waveformRef = React.useRef(null);
  const wavesurfer = React.useRef(null);

  // create new WaveSurfer instance
  // On component mount and when url changes
  React.useEffect(() => {
    const options = formWaveSurferOptions(waveformRef.current);
    wavesurfer.current = WaveSurfer.create(options);

    wavesurfer.current.load(url);

    wavesurfer.current.on("ready", () => {
      // https://wavesurfer-js.org/docs/methods.html
      if (wavesurfer.current) {
        wavesurfer.current.setVolume(0);
        wavesurfer.current.setMute(true);
      }
    }); 

    // When seek is fired, it throws the progress in the callback function
    wavesurfer.current.on("seek", (progress) => {
      onClick(progress * 100);
    });
    
    // Removes events, elements and disconnects Web Audio nodes.
    // when component unmount
    return () => {
      wavesurfer.current.destroy();
    }
  }, [url, onClick]);

  React.useEffect(() => {
    let timeoutId;
    wavesurfer.current.play(currentTime);

    if (!isPlaying) {
      timeoutId = setTimeout(() => {
        wavesurfer.current.pause();
      }, 0);
    }

    return () => {
      clearTimeout(timeoutId);
    }
  }, [currentTime, isPlaying]);

  return (
    <div className="waveform">
      <div id="waveform" ref={waveformRef} />
    </div>
  );
}

export default Waveform;
