import React from 'react';
import Waveform from "./Waveform";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IoPlaySkipBackSharp } from "react-icons/io5";
import { IoPlaySharp } from "react-icons/io5";
import { IoPauseSharp } from "react-icons/io5";
import { IoPlaySkipForwardSharp } from "react-icons/io5";
import { BiShuffle } from "react-icons/bi";
import { IoShareSocialSharp } from "react-icons/io5";
import formatTime from "./formatTime";
import "./player_styles.css";


const Player = ({ song, prevNextSong, shareTheURL, shuffle, isShuffled }) => {
  const [isPlaying, setIsPlaying] = React.useState(true);
  const [currentTime, setCurrentTime] = React.useState(0);
  const [duration, setDuration] = React.useState(0);
  
  // reference the audio component / for console -> document.querySelector('audio').currentTime etc
  const audioPlayer = React.useRef();

  const togglePlayPause = () => {
    if (audioPlayer.current.paused) {
      audioPlayer.current.play();
      setIsPlaying(true);
    } else { 
      audioPlayer.current.pause();
      setIsPlaying(false);
      setCurrentTime(audioPlayer.current.currentTime);
    }
  }

  const percentageToCurrentTime = React.useCallback((percentage) => {
    const newCurrentTime = Math.floor(audioPlayer.current.duration / 100 * percentage);
    audioPlayer.current.currentTime = newCurrentTime;
    setCurrentTime(audioPlayer.current.currentTime);
  }, []);

  const checkAudioState = () => {
    setIsPlaying(!audioPlayer.current.paused);
  }

  const urlCopied = () => {
    shareTheURL();
    toast("Copied to your clipboard!");
  }

  React.useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 32) {
        event.preventDefault();
        togglePlayPause();
      }
  
      if (event.keyCode === 37) {
        event.preventDefault();
        audioPlayer.current.currentTime -= 5;
        setCurrentTime(audioPlayer.current.currentTime);
      }
  
      if (event.keyCode === 39) {
        event.preventDefault();
        audioPlayer.current.currentTime += 5;
        setCurrentTime(audioPlayer.current.currentTime);
      }
    }
  
    window.addEventListener('keydown', handleKeyDown);
    const intervalId = window.setInterval(() => {
      checkAudioState();
      setCurrentTime(audioPlayer.current.currentTime);
    }, 1000);
    audioPlayer.current.onloadedmetadata = () => {
      setDuration(audioPlayer.current.duration);
    }
    
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      clearInterval(intervalId);
    };
  }, []);

  React.useEffect(() => {
    const checkIfEnded = () => {
      if (audioPlayer.current.ended) {
        prevNextSong(1);
      }
    }

    const intervalId = window.setInterval(() => {
      checkIfEnded();
    }, 1000);

    return () => {
      clearInterval(intervalId);
    }
  }, [song, prevNextSong]);

  return (
    <div className="player">
      <audio id="audio-player" ref={audioPlayer} autoPlay src={`/playlist/${song.songSrc}`} preload="metadata" />
      <img width="300px" src={`/playlist/${song.albumCover}`} alt=""></img>
      <Waveform url={`/playlist/${song.songSrc}`}
        currentTime={currentTime} onClick={percentageToCurrentTime} isPlaying={isPlaying} />
        <div className="text-panel">
          <div className="song-text-player">
            <div className="songName-player">{song.songName}</div>
            <div className="artistName-player">{song.artistName}</div>
          </div>
          <div className="song-times">
            {/* current time */}
            <span>{formatTime(currentTime)} </span>
            {/* duration */}
            <span>/ {formatTime(duration)}</span>
          </div>
        </div>
      <div className="btn-wrap">
      {/* style={{fill: isShuffled ? 'aquamarine ' : 'white'}} */}
        <button><BiShuffle className={isShuffled ? "isShuffled" : ""} onClick={shuffle} /></button> 
        <button onClick={() => {
            prevNextSong(-1);
            audioPlayer.current.currentTime = 0;
          }
        }><IoPlaySkipBackSharp /></button>
        <button onClick={togglePlayPause} >{isPlaying ? <IoPauseSharp /> : <IoPlaySharp />}</button>
        <button onClick={() => prevNextSong(1)}><IoPlaySkipForwardSharp /></button>
        <button onClick={urlCopied}><IoShareSocialSharp /></button>
        <ToastContainer 
          bodyclassName="toaster"
          position="bottom-center"
          autoClose={2000}
          hideProgressBar={true}
          newestOnTop={true}
          closeButton={false}
          closeOnClick={false}
          rtl={false}
          draggable
        />
      </div>
    </div>
  )
}

export default Player;
