import React from 'react';
import './styles.css';

const Photos = () => {
  return (
    <div>
      <h3>Photos</h3>
    </div>
  )
}

export default Photos
