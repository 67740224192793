import React from 'react';
import Views from './assets/Views';
import Downloads from './assets/Downloads';
import Statistics from '../Statistics';
import './styles.css';

const photoSelection = ['7dSXHxPfba8', '9wLq0zC_sOE', 'IH3yZBq8Vwo', '6A43h8yRRqU', 'dqeMwYW9fCY', 'drDXXCDNlYo', 'kDCbtCguvAQ', 'uzIqGHLWcks', 'IdC1aK7fzWQ'];

const formatResponse = (response) => {
  const photos = [];
  response.forEach(photo => {
    if (photoSelection.includes(photo.id)) {
      photos.push({
        id: photo.id,
        url: photo.urls.regular,
        alt: photo.alt_description,
        small: photo.urls.small,
        thumb: photo.urls.thumb,
        views: photo.statistics.views.total,
        downloads: photo.statistics.downloads.total
      });
    }
  });
  return photos
}

const Unsplash = () => {
  const [photos, setPhotos] = React.useState([]);
  const [stats, setStats] = React.useState({});
  const [hasLoaded, setHasLoaded] = React.useState(false);

  React.useEffect(() => {
    fetch('https://api.unsplash.com/users/linasilivanova/photos?client_id=HH-3L0XnFrU5aqS8ajYN9sTN0mzl9cpxj6-zw0PpGxg&per_page=35&stats=true')
    .then(response => response.json())
    .then(formatResponse)
    .then(setPhotos);

    fetch('https://api.unsplash.com/users/linasilivanova/statistics?client_id=HH-3L0XnFrU5aqS8ajYN9sTN0mzl9cpxj6-zw0PpGxg')
    .then(response => response.json())
    .then(response => {
      setStats({
        views: response.views.total,
        downloads: response.downloads.total
      });
      setHasLoaded(true);
    });

  }, []);

  return (
    <div>
      <Statistics stats={stats} hasLoaded={hasLoaded} />
      <h3>Latest on <a className="links" href="https://unsplash.com/@linasilivanova" rel="noreferrer" target="_blank">Unsplash</a></h3>
      <div className="grid-container">
        {photos.map(photo => (
          <div className="photo-container" key={photo.id}>
            <div className="hover-shadow" />
            <img className="photo-itself" src={photo.url} alt={photo.alt} />
            <div className="image-stats">
              <div className="views"><span className="image-svg"><Views /></span><span className="image-numbers">{photo.views.toLocaleString()}</span></div>
              <div className="downloads"><span className="image-svg"><Downloads /></span><span className="image-numbers">{photo.downloads.toLocaleString()}</span></div>
          </div>
          </div>
      ))}
      </div>
    </div>
  )
}

export default Unsplash;
