import React from 'react';
import "./styles.css";

const Projects = () => {
  return (
    <div>
      <div>
        <i>Coming soon.</i>
      </div>
      <div>
        <i>My <a target="_blank" href="https://www.notion.so/polinasvn/Polina-Silivanova-dd97173f93ad493ab9ae3c34260d4484" rel="noopener noreferrer">resume</a>.</i>
      </div>
    </div>
  )
}

export default Projects
