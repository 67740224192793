import React from 'react';
import songList from './songs.json';
import Player from './Player';
import formatTime from "./formatTime";
import './styles.css';

const Playlist = () => {
  const [selectedSong, setSelectedSong] = React.useState(songList[0]);
  const [isShuffled, toggleShuffle] = React.useState(false);
  const [songHistory, setSongHistory] = React.useState([]);
  const [songs, setSongs] = React.useState(songList);
  
  const setPrevNextSong = (value) => {
    if (isShuffled && value === 1) {
      return shuffle();
    } 
    if (isShuffled && value === -1 && songHistory.length > 1) {
      const index = songHistory[songHistory.length - 2];
      setSong(songs[index], true);
      return;
    }
    else if (isShuffled && value === -1 && songHistory.length === 1) { 
      const index = songHistory[0];
      setSong(songs[index]);
      return;
    }

    const index = songs.findIndex(song => song.songName === selectedSong.songName);
    if (index === songs.length -1 && value === 1) {
      setSong(songs[0]);
      return;
    }
    if (index === 0 && value === -1) {
      setSong(songs[songs.length -1]);
      return;
    }
    setSong(songs[index + value]);
  }

  const shuffle = () => {
    const indexCurrentSong = songs.findIndex(song => song.songName === selectedSong.songName);
    let randomNumber = indexCurrentSong;
    while (randomNumber === indexCurrentSong) {
      randomNumber = Math.floor(Math.random() * (6 - 0) + 0);
    }
    setSong(songs[randomNumber]);
  }
  
  const shareTheURL = () => {
    navigator.clipboard.writeText(window.location.host + window.location.pathname + '?song=' + selectedSong.id);
  }

  const getDuration = (src) => {
    return new Promise((resolve) => {
      const au = document.createElement('audio');

      au.src = src;
      au.addEventListener('loadedmetadata', () => {
        resolve(au.duration);
      }, false);
    });
  }

  const setSong = React.useCallback((song, pop = false) => {
    setSelectedSong(song);

    if (!pop) {
      const index = songs.findIndex(s => s.songName === song.songName);
      setSongHistory([...songHistory, index]);
    } else {
      const newSongHistory = songHistory;
      newSongHistory.pop();
      setSongHistory(newSongHistory);
    }
  }, [songHistory, songs]);
  
  React.useEffect(() => {
    const getSongUrl = () => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const songId = urlParams.get('song');
      const song = songs.find(s => songId === s.id); // s - song
      if (song) {
        setSong(song);
        window.history.pushState(null, null, window.location.pathname);
      }
    }

    getSongUrl();
  }, [setSong, songs]);

  React.useEffect(() => {
    const setDurations = () => {
      const newSongs = songList;
      const promises = [];

      newSongs.forEach((song) => {
        promises.push(getDuration(`/playlist/${song.songSrc}`).then((duration) => {
          song.duration = formatTime(duration);
        }));
      });

      Promise.all(promises).then(() => setSongs([...newSongs]));
    }

    setDurations();
  }, []);

  return (
    <div>
      <div className="big-wrap">
        {!!selectedSong && <Player song={selectedSong} prevNextSong={val => setPrevNextSong(val)} shareTheURL={shareTheURL} shuffle={() => toggleShuffle(!isShuffled)} isShuffled={isShuffled} />}
        <div className="playlist">
          <div className="blur-back" style={{ backgroundImage: `url(/playlist/${selectedSong.albumCover})` }}></div>
          {songs.map(song => (<div className="each-song" key={song.songName} onClick={() => setSong(song)}>
              <div className="song-text">
                <div className="start">
                  <div className="songName">{song.songName}</div>
                  <div className="artistName">{song.artistName}</div>
                </div>
                <div className="duration">{song.duration}</div>
              </div>
          </div>))}
        </div>
      </div>
    </div>
  )
}

export default Playlist;
