import React from 'react';
import GlowyText from '../GlowyText/';
import Footer from '../Footer/';
import './styles.css';

const Home = () => {
  return (
    <div>
      <h1 className="gradient-text">Polina Silivanova</h1>
      <div className="description_container">
        <p>Mostly a <GlowyText text="photographer" className="photographer" to="/unsplash" /> and a <GlowyText text="traveller" className="traveller" to="/" />, on my way to become a <GlowyText text="front-end developer" className="developer" to="/projects" />.</p>
  
        <div className="desc_location">
          <p>Currently based in <i>Nice, France</i></p>
          <span className="wave-wrapper"><span className="wave"></span></span>
        </div>
        <Footer />
      </div>
    </div>
  )
}

export default Home;