import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from "react-router";
import './styles.css';
import ToggleDark from '../ToggleDark/';


const Header = ({ location }) => {
  const currentPathname = location.pathname;

  return (
    <div className="nav-bar">
      <span className="nav-left">
        <Link to="/" className={currentPathname === '/' ? 'active' : ''}>Home</Link>
        {/* <Link to="/photos" className={currentPathname === '/photos' ? 'active' : ''}>Photos</Link> */}
        <Link to="/unsplash" className={currentPathname === '/unsplash' ? 'active' : ''}>Unsplash</Link>
        <Link to="/projects" className={currentPathname === '/projects' ? 'active' : ''}>Projects</Link>
        <Link to="/playlist" className={currentPathname === '/playlist' ? 'active' : ''}>Playlist</Link>
      </span>
      <span className="nav-right">
        <ToggleDark className="toggleDark" />
      </span>
    </div>
  )
}

export default withRouter(Header);
