const formatTime = (floatingSeconds) => {
  if (isNaN(floatingSeconds)) {
    return '0:00';
  }
  const minutes = Math.floor(floatingSeconds / 60);
  const seconds = Math.floor(floatingSeconds - (minutes * 60)).toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false
  });
  return `${minutes}:${seconds}`;
}

export default formatTime;