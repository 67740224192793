import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from './components/Home/';
import Photos from './components/Photos/';
import Unsplash from './components/Unsplash/';
import Projects from './components/Projects/';
import Playlist from './components/Playlist/';
import Header from './components/Header/';

function App() {
  return (
    <div className="homePage">
      <div className="wrapper">
      <BrowserRouter>
        <Header />
        <Switch>
          <Route exact path="/"><Home /></Route>
          <Route exact path="/photos"><Photos /></Route>
          <Route exact path="/unsplash"><Unsplash /></Route>
          <Route exact path="/projects"><Projects /></Route>
          <Route exact path="/playlist"><Playlist /></Route>
        </Switch>
      </BrowserRouter>
      
      </div>
    </div>
  );
}

export default App;
