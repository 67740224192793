import React from 'react';
import './styles.css';
import Twitter from './assets/Twitter';
// import Unsplash from './assets/Unsplash';
import GitHub from './assets/Github';
import Instagram from './assets/Instagram';

const Footer = () => {
  return (
    <div className="footer">
      <div>My resume on <a href="https://polinasvn.notion.site/Polina-Silivanova-dd97173f93ad493ab9ae3c34260d4484" target="_blank" rel="noopener noreferrer">Notion</a>.</div>
      <div className="socials-container">
        <a href="https://twitter.com/linasilivanova" target="_blank" rel="noopener noreferrer"><Twitter className="twitter" /></a>  
        <a href="https://github.com/linasvn" target="_blank" rel="noopener noreferrer"><GitHub className="github" /></a>
        <a href="https://instagram.com/linasilivanova" target="_blank" rel="noopener noreferrer"><Instagram className="instagram" /></a>
      </div>
    </div>
  )
}

export default Footer;
