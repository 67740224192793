import React from 'react';
import Moon from './assets/moon';
import Sun from './assets/sun';
import './styles.css';

const isDarkMode = () => {
  // const el = document.body;
  const el = document.getElementsByTagName('html')[0];
  return el.className.includes('dark');
}

const ToggleDark = ({ className }) => {
  const [appearance, setAppearance] = React.useState(isDarkMode() ? 'dark' : 'light');
  
  React.useEffect(() => {
    const setBodyClass = () => {
      // const el = document.body;
      const el = document.getElementsByTagName('html')[0];
      appearance === 'light' ? el.classList.remove('dark') : el.classList.add('dark');
    }
    
    setBodyClass();
  }, [appearance])

  return (
    <div className={className} onClick={() => setAppearance(appearance === 'light' ? 'dark' : 'light')}>
      <div className={`icon-mode ${appearance}`}>
        <Sun className="sun" />
        <Moon className="moon" />
      </div>
    </div>
  )
}

export default ToggleDark;
